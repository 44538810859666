<template>
    <div v-show="!showCreate">
      <b-row>
        <b-col>
          <b-card class="justify-content-md-center style-term overflow-auto">
           
              <h3 class="text-center">Política de privacidade</h3>
              <hr />
              <p class="text-justify" 
                v-html="terms.data.term_contract"
              >
              </p>
          </b-card>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
  },
  data() {
    return {
      showSearch: false,
      showCreate: false,
      isLoading: true,
      isLoadingLest: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      term_contract: null,
      term_lgpd: null,
      dateTerm: {
        term_contract: null,
        term_lgpd: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("terms", ["terms"])
  },
  methods: {
    ...mapActions("terms", ["fetchLestTerm"]),
  },
  created() {
    this.fetchLestTerm();
  },
};
</script>

<style lang="scss">
.style-term {
  width: 100%;
  height: 648px;
}
</style>
